import React, {useEffect, useState} from "react";
import {Box, Typography, useTheme} from "@mui/material";
import SiNoVisual from "./DomandeVisual/SiNoVisual";
import QuizVisual from "./DomandeVisual/QuizVisual";
import {firestore} from "../../firebase/clientApp";
import axios from "axios";
import WordcloudVisual from "./DomandeVisual/WordcloudVisual";
import {useParams} from "react-router-dom";
import SentimentVisual from "./DomandeVisual/SentimentVisual";
import {ClickToVisual} from "./DomandeVisual/ClickToVisual";
import {SendPhotoVisual} from "./DomandeVisual/SendPhotoVisual";
import {API_URL} from "../../config";
import {useQuery} from "../../custom-hooks/useQuery";
import {AudioVisual} from "./DomandeVisual/AudioVisual";
import ReorderVisual from "./DomandeVisual/ReorderVisual";
import ReserveSpotVisual from "./DomandeVisual/ReserveSpotVisual";

export default function ControlContent({idPlaying, url, eventId, activeTheme}) {
    const {mode} = useParams()
    const [elementPlaying, setElementPlaying] = useState([])
    const [result, setResult] = useState({})
    const [timer, setTimer] = useState(2000)
    const theme = useTheme()
    const query = useQuery()
    const luma = query.get('mode') === 'luma'
    const question = query.get('question') === 'true'

    const colors = Object.keys(activeTheme).length > 0 ? activeTheme.data().colors : []

    useEffect(() => {
        if (elementPlaying.tipo === 'sendPhoto') {
            if(elementPlaying.hideResult) {
                setTimer(2000)
            } else {
                setTimer(10000)
            }
        } else {
            setTimer(2000)
        }
    }, [elementPlaying]);

    useEffect(() => {
        if (idPlaying !== '') {
            getElementPlaying()
            getData()
            window.clearInterval()
            let intervalId = window.setInterval(() => getData(), timer);
            return () => {
                window.clearInterval(intervalId)
            }
        }
    }, [idPlaying, timer])


    async function getElementPlaying() {
        await idPlaying && firestore.collection(url + '/queue').doc(idPlaying).onSnapshot(s => {
            setElementPlaying({...s.data(), id: s.id})
        })
    }

    async function getData() {
        idPlaying !== undefined && await axios.get(`${API_URL}/answer/${eventId}/${idPlaying}`).then(r => {
            setResult(r)
        })
    }


    return (
        <div style={{height: '100%'}} className="css-boxesterno">
            {question ? <Box width={'100%'} component={'div'}>
                    <Typography variant={"h4"}>{elementPlaying.domanda}</Typography>
                </Box>
                :
                <Box width={'100%'} height={'100%'} component={'div'} sx={{
                    p: ['sendPhoto', 'audio'].includes(elementPlaying.tipo) ? 0 : 2,
                    backgroundColor: luma ? 'black' : 'inherit'
                }}>
                    {(() => {
                        switch (elementPlaying.tipo) {
                            case 'clickTo':
                                return <ClickToVisual elementPlaying={elementPlaying} result={result} colors={colors}/>
                            case 'siNo':
                                return <SiNoVisual elementPlaying={elementPlaying} result={result} colors={colors}
                                                   luma={luma}/>
                            case 'sendPhoto':
                                return <SendPhotoVisual domanda={idPlaying} result={result} getData={getData}
                                                        luma={luma}/>
                            case 'sentiment':
                                return <SentimentVisual elementPlaying={elementPlaying} result={result} colors={colors}
                                                        luma={luma}/>
                            case 'quiz':
                            case 'poll':
                            case 'multiPoll':
                                return <QuizVisual elementPlaying={elementPlaying} result={result} colors={colors}
                                                   luma={luma}/>
                            case 'reorder':
                                return <ReorderVisual elementPlaying={elementPlaying} result={result} colors={colors}
                                                      luma={luma}/>
                            case 'wordcloud':
                                return <WordcloudVisual elementPlaying={elementPlaying} result={result} colors={colors}
                                                        luma={luma}/>
                            case 'audio':
                                return <AudioVisual domanda={idPlaying} result={result} getData={getData} luma={luma}/>
                            case 'reserveSpot':
                                return <ReserveSpotVisual elementPlaying={elementPlaying} result={result}/>
                            /*case 'toolpress':
                                return <QnaVisual elementPlaying={elementPlaying} result={result} colors={colors}/>*/
                            default:
                                return null
                        }
                    })()}
                </Box>
            }
        </div>
    )
}
