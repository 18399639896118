import React, {useEffect, useState} from "react";
import ReactWordcloud from 'react-wordcloud';

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import {useQuery} from "../../../custom-hooks/useQuery";
import {Button, Card, Icon, IconButton, ListItem, ListItemSecondaryAction, ListItemText} from "@mui/material";
import {DeleteForever, RocketLaunch, ThumbUp} from "@mui/icons-material";
import axios from "axios";
import {API_URL} from "../../../config";
import {useParams} from "react-router-dom";
import List from "@mui/material/List";
import CustomConfirmDialog from "../../shared/CustomConfirmDialog";

export default function WordcloudVisual({result}){
    const query = useQuery()
    const control = query.get('control') === 'true'

    const [parole, setParole] = useState([])
    const padding = query.get("padding") ?? 2
    const rotation = query.get("rotation") === "true"
    const min = query.get("min") ?? 30
    const max = query.get("max") ?? 100
    const font = query.get("font") ?? "Roboto"
    const uppercase = query.get("uppercase") === "true"
    const colors = query.get("colors") ?? "f1ebe9"

    const options = {
        colors: colors.split(",").map((color) => "#" + color),
        enableTooltip: false,
        deterministic: true,
        fontFamily: font,
        fontSizes: [min, max],
        textTransform: uppercase ? "uppercase" : "none",
        fontStyle: "normal",
        fontWeight: "bold",
        padding: padding,
        rotations: rotation ? 2 : 0,
        rotationAngles: [0, -90],
        scale: "sqrt",
        spiral: "archimedean",
        transitionDuration: 1000,
    };

    useEffect(() => {
        if(Array.isArray(result.data)){
            if (uppercase){
                let res = []
                result.data.map((parola) => res.push({text: parola.text.toUpperCase(), value: parola.value}))
                setParole(res.slice(0,80))
            } else {
                setParole(result.data.slice(0,80))
            }
        }
    }, [result])
    return(
        !control ?
            <ReactWordcloud words={parole} options={options}/>
        : <ControlWordcloud words={parole}/>
    )
}

function ControlWordcloud({words}) {
    const {id, domanda} = useParams()

    const handleBoostVotes = (answer) => {
        axios.post(`${API_URL}/answer/${id}/${domanda}`, {
            answer: answer,
            user: 'admin'
        })
            .then(response => console.log("boosted",response))
            .catch( error => console.log("error boosting",error))
    }

    const handleBanWord = (answer) => {
        axios.post(`${API_URL}/answer/${id}/${domanda}/wordcloud/ban`, {
            word: answer,
        })
            .then(response => console.log("banned",response))
            .catch( error => console.log("error banning",error))
    }

    return (<List sx={{width: '100%'}}>
        {
            words?.map((word, i) => (
                <ListItem component={Card} sx={{mb: 1}}>
                    <ListItemText
                        primary={word.text.toString()}
                        secondary={word.value}
                    />
                    <ListItemSecondaryAction>
                        <Button sx={{marginRight: 1}} size={'small'}
                                onClick={() => handleBoostVotes(word.text)}
                                endIcon={<RocketLaunch/>}>
                            {`Boost voti`}
                        </Button>
                        <CustomConfirmDialog variant={'outlined'} size={'small'}
                                             label={"Elimina"} icon={<DeleteForever/>}
                                             confirmAction={() => handleBanWord(word.text)} />
                    </ListItemSecondaryAction>
                </ListItem>
            ))
        }
    </List>);
}
