import React, {useEffect, useState} from "react";
import DialogTitle from "@mui/material/DialogTitle";
import {Container, TextField} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import QuizForm from "./FormDomande/QuizForm";
import {firestore} from "../firebase/clientApp";
import {useParams} from "react-router-dom";
import {ClickToForm} from "./FormDomande/ClickToForm";
import LinkForm from "./FormDomande/LinkForm";
import SendPhotoForm from "./FormDomande/SendPhotoForm";

export default function EditQueueElement({open, handleCloseEditDialog, element}) {
    const {id} = useParams()
    const [domanda, setDomanda] = useState('')
    const [risposte, setRisposte] = useState([])
    const [soglia, setSoglia] = useState(null)
    const [corretta, setCorretta] = useState('')
    const [hideResult, setHideResult] = useState(false)
    const [colore, setColore] = useState('')

    useEffect(() => {
        setDomanda(element.domanda)
        setRisposte(element.risposte)
        setSoglia(element.soglia)
        setCorretta(element.corretta)
        setHideResult(element.hideResult)
        setColore(element.colore)
    }, [element])

    function handleSetDomanda(event) {
        setDomanda(event.target.value)
    }

    const handleSetRisposte = (risposte) => {
        setRisposte([...risposte])
    }

    const handleSetColore = (col) => {
        setColore(col)
    }

    const handleSetCorretta = (corretta) => {
        setCorretta(corretta)
    }

    const handleHideResult = (hideResult) => {
        setHideResult(hideResult)
    }

    function handleEdit() {
        let updated = element
        updated.domanda = domanda
        if (risposte) {updated.risposte = risposte}
        if (soglia) {updated.soglia = soglia}
        if (element.tipo === 'quiz') {
            updated.hideResult = hideResult
            updated.corretta = corretta
        }
        firestore
            .collection('users/plesh/events/' + id + '/queue')
            .doc(element.id)
            .set({...updated}, {merge: true})
            .then(r => handleCloseEditDialog())
    }

    return (
        <Dialog open={open} onClose={handleCloseEditDialog} fullWidth>
            <DialogTitle>Edit queue element</DialogTitle>
            <Container>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Question"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={domanda}
                    onChange={handleSetDomanda}
                />
                {(() => {
                    switch (element.tipo) {
                        case 'clickTo':
                            return <ClickToForm handleSetColore={handleSetColore} colore={colore}/>
                        case 'links':
                            return <LinkForm handleSetLinks={handleSetRisposte} links={risposte}/>
                        case 'quiz':
                            return <QuizForm handleSetRisposte={handleSetRisposte} risp={risposte}
                                             handleSetCorretta={handleSetCorretta} corr={corretta}
                                             handleHideResult={handleHideResult} hideResult={hideResult}/>
                        case 'poll':
                        case 'multiPoll':
                        case 'reorder':
                            return <QuizForm handleSetRisposte={handleSetRisposte} risp={risposte} poll={true}/>
                        case 'sendPhoto':
                            return <SendPhotoForm handleHideResult={handleHideResult} hideResult={hideResult}/>
                        default:
                            return null
                    }
                })()}
                <DialogActions>
                    <Button onClick={handleCloseEditDialog}>Cancel</Button>
                    <Button onClick={handleEdit}>Save</Button>
                </DialogActions>
            </Container>
        </Dialog>
    )
}
