import React, {useEffect, useMemo, useState} from "react";
import {Box, Fade, Grid, Stack, Typography} from "@mui/material";
import List from "@mui/material/List";

const ReorderVisual = ({result, elementPlaying, colors, luma}) => {
    console.log("result:",result)

    const [loading, setLoading] = useState(false)
    const [triggerTransition, setTriggerTransition] = useState(false)
    const [res, setRes] = useState(elementPlaying.risposte)

    useEffect(() => {
        if(!!result?.data && JSON.stringify(result?.data) !== JSON.stringify(res)) {
            console.log("!!result?.data:",result?.data)
            console.log("res",res)
            setRes(result?.data)
        }
    }, [result])

    useEffect(() => {
        setLoading(true)
        if(res) {
            console.log("res:", res)
            setTriggerTransition(false)
            setTimeout(() => {
                console.log("Delayed for 1 second.");
                setLoading(false)
            }, 800);
        }
    }, [res])

    useEffect(() => {
        if(!loading)
            setTriggerTransition(true)
    }, [loading])

    return <div style={{width: '100%', height: '100%', textAlign: 'left', position: 'relative'}}>
        <Stack height={'90vh'} width={'100%'} spacing={5}>
            {
                res?.map((res, i) => (
                    <Grid key={i} container alignItems={'start'}>
                        <Grid item xs={2} minHeight={'150px'}>
                            <Typography zIndex={'99'} component={"div"} variant={'h4'}
                                        sx={{
                                            color: luma ? "white" : '#f1ebe9', opacity: '1'
                                        }}>
                                {++i}
                            </Typography>
                        </Grid>
                        {!loading && !!res &&
                            <Grid item xs={10}>
                                <Fade in={triggerTransition}>
                                    <Typography zIndex={'99'} component={"div"} variant={'h4'}
                                                sx={{
                                                    color: luma ? "white" : '#f1ebe9', opacity: '1'
                                                }}>
                                        {res}
                                    </Typography>
                                </Fade>
                            </Grid>}
                    </Grid>
                ))
            }
        </Stack>
    </div>
}

export default ReorderVisual