import React, {useEffect, useState} from "react";
import {Box, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {
    ContentCopy,
    DragIndicator,
    Edit,
    Gavel,
    MoreVert,
    OpenInNew,
    PlayArrow,
    PushPin,
    Stop,
    TextFields,
    Visibility,
    VisibilityOff
} from "@mui/icons-material";
import QueuePin from "./QueuePin";
import RemoveQueueElementButton from "./RemoveQueueElementButton";
import EditQueueElement from "./EditQueueElement";
import {Link, useParams} from "react-router-dom";
import {Draggable} from "react-beautiful-dnd";
import ResetQueueElementResultButton from "./ResetQueueElementResultButton";
import Tooltip from "@mui/material/Tooltip";

export default function QueueElement({
                                         element,
                                         handleRemoveElement,
                                         handlePlayElement,
                                         elementPlaying,
                                         playing,
                                         index,
                                         pinElement
                                     }) {
    const {id} = useParams()
    const [play, setPlay] = useState(false)
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);

    const handleMenu = (e) => {
        setAnchorEl(e.currentTarget)
    }

    useEffect(() => {
        if (element.id === elementPlaying) {
            setPlay(true)
        } else {
            setPlay(false)
        }
    }, [elementPlaying])

    function updatePlay() {
        handlePlayElement(element.id)
    }

    const handleOpenEditDialog = () => {
        setAnchorEl(null)
        setOpen(true)
    }

    const handleCloseEditDialog = () => {
        setOpen(false)
    }

    const handleCopyId = (elementId) => {
        navigator.clipboard.writeText(elementId)
            .then(() => window.alert('ID copiato negli appunti'))
    }

    return (
        <Draggable key={element.id} draggableId={element.id} index={index}>
            {(provider) => (
                <Stack direction={{xs: 'column', md: 'row'}} alignItems={{xs: 'start', md: 'center'}}
                       justifyContent={'space-between'} sx={{
                    background: element.id === elementPlaying ? '#414272' : '#1C1C3D',
                    borderRadius: '0.5rem',
                    my: 1,
                    width: '100%',
                    px: 1,
                    py: 0.7
                }}
                       ref={provider.innerRef}
                       {...provider.draggableProps}
                >
                    <Stack direction={{xs: 'column', md: 'row'}} alignItems={{xs: 'start', md: 'center'}}
                           sx={{width: {xs: '100%', md: 'auto'}}}
                           spacing={2}
                    >
                        <Box sx={{pr: 1, alignSelf: 'center', display: element.pinned ? 'none' : 'block'}}
                             {...provider.dragHandleProps}
                        >
                            <DragIndicator/>
                        </Box>
                        <Box sx={{alignSelf: 'center'}}>
                            <QueuePin tipo={element.tipo}/>
                        </Box>
                        {
                            element.tipo === 'divider' ?
                                <Typography sx={{py: 0.7}}
                                            textAlign={"left"}>---------------------------- {element.domanda} ----------------------------</Typography>
                                :
                                <Typography sx={{py: 0.7}} textAlign={"left"}>{element.domanda}</Typography>
                        }
                        {
                            element.tipo === 'quiz'
                                ? <Tooltip
                                    title={element.hideResult === true ? "Risultato nascosto all'utente" : "Risultato visibile all'utente"}
                                    children={element.hideResult === true
                                        ? <VisibilityOff fontSize={'small'}/> : <Visibility fontSize={'small'}/>}/>
                                : null
                        }
                    </Stack>
                    <Stack direction={'row'} alignItems={'center'}>
                        <Box>
                            <IconButton size={'large'} onClick={handleMenu}>
                                <MoreVert fontSize={'inherit'}/>
                            </IconButton>
                            <Menu open={openMenu} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
                                {element.id !== elementPlaying &&
                                    <RemoveQueueElementButton id={element.id}
                                                              handleRemoveElement={handleRemoveElement}/>
                                }
                                {element.id !== elementPlaying &&
                                    <MenuItem onClick={handleOpenEditDialog}>
                                            <ListItemIcon>
                                                <Edit/>
                                            </ListItemIcon>
                                            <ListItemText>Modifica</ListItemText>
                                        </MenuItem>
                                }
                                <MenuItem onClick={() => pinElement(element.id)}>
                                    <ListItemIcon>
                                        <PushPin/>
                                    </ListItemIcon>
                                    <ListItemText>{element.pinned ? "Rimuovi da home" : "Fissa in home"}</ListItemText>
                                </MenuItem>
                                {(element.tipo !== 'altro' && element.tipo !== 'links') &&
                                    <MenuItem component={["toolpress"].includes(element.tipo) ? 'a' : Link}
                                              to={'/control/' + id + '/domanda/' + element.id}
                                              href={`https://control.toolpress.app/conferenza/${element.conferenza}`}
                                              target={'_blank'}>
                                        <ListItemIcon>
                                            <OpenInNew/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            {["toolpress"].includes(element.tipo) ? "Vai a Toolpress" : "Grafico risultati"}
                                        </ListItemText>
                                    </MenuItem>}
                                <MenuItem component={Link} target={'_blank'}
                                          to={'/control/' + id + '/domanda/' + element.id + '?question=true'}>
                                    <ListItemIcon>
                                        <TextFields/>
                                    </ListItemIcon>
                                    <ListItemText>
                                        Testo domanda
                                    </ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => handleCopyId(element.id)}>
                                    <ListItemIcon>
                                        <ContentCopy/>
                                    </ListItemIcon>
                                    <ListItemText>Copia ID domanda</ListItemText>
                                </MenuItem>
                                {
                                    ["sendPhoto", "poll", "quiz", "multiPoll", "wordcloud", "reserveSpot"].includes(element.tipo) &&
                                    <MenuItem component={Link} target={'_blank'}
                                              to={'/control/' + id + '/domanda/' + element.id + '?control=true'}>
                                        <ListItemIcon>
                                            <Gavel/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            Moderazione
                                        </ListItemText>
                                    </MenuItem>
                                }
                                {element.id !== elementPlaying
                                    && !["divider", "toolpress", "links"].includes(element.tipo)
                                    && <>
                                        <Divider/>
                                        <ResetQueueElementResultButton elementId={element.id}/>
                                    </>}
                            </Menu>
                            <EditQueueElement handleCloseEditDialog={handleCloseEditDialog}
                                              element={element} open={open}/>
                        </Box>


                        {playing &&
                            <Box>
                                <IconButton disabled={element.tipo === 'divider'}
                                            onClick={updatePlay}
                                            edge="end" aria-label="play" size={'large'}>
                                    {
                                        element.id === elementPlaying ?
                                            <Stop fontSize={'inherit'}/>
                                            :
                                            <PlayArrow fontSize={'inherit'}/>
                                    }
                                </IconButton>
                            </Box>
                        }
                    </Stack>
                </Stack>)}
        </Draggable>
    )
}
